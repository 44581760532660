import { Injectable } from '@angular/core';
import { GridService } from '../grid.model';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  concatMap,
  debounce,
  debounceTime,
  distinctUntilChanged,
  EMPTY,
  Observable,
  of,
  switchMap,
  tap,
  timer,
} from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

// Define interfaces for the request and response payloads
export interface GridRequest {
  gofor: string;
  body: any;
  pagination: {
    pageSize: number;
    currentPage: number;
  };
  filter: any;
  sort: { colId: string; sort: string } | null;
  searchText: string;
}

export interface GridResponse {
  data: any[];
  first_index: number;
  last_index: number;
  total_pages: number;
  count: number;
  current_count: number;
  current_page: number;
}

@Injectable({
  providedIn: 'root',
})
export class AgGridService implements GridService {
  public columns: any[] = [];
  public apiUrl$ = new BehaviorSubject<string>('');
  public apiPayload$ = new BehaviorSubject<any>({});
  public loadingState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  private filterModel$ = new BehaviorSubject<any>({});
  private sortModel$ = new BehaviorSubject<{
    colId: string;
    sort: string;
  } | null>(null);
  private searchText$ = new BehaviorSubject<string>('');

  private pageSize$ = new BehaviorSubject<number>(10);
  private currentPage$ = new BehaviorSubject<number>(1);
  private totalPages$ = new BehaviorSubject<number>(0);
  private pages$ = new BehaviorSubject<number[]>([]);
  private firstIndex$ = new BehaviorSubject<number>(0);
  private lastIndex$ = new BehaviorSubject<number>(0);
  private totalCount$ = new BehaviorSubject<number>(0);

  constructor(private http: HttpClient) {}

  setApiUrl(url: string) {
    this.apiUrl$.next(url);
  }

  setApiPayload(payload: string) {
    this.apiPayload$.next(payload);
  }

  setColumns(columns: any[]): void {
    this.columns = columns;
  }

  getColumns(): any[] {
    return this.columns;
  }

  getPagination(): {
    pageSize: number;
    currentPage: number;
    totalPages: number;
    firstIndex: number;
    lastIndex: number;
    totalCount: number;
  } {
    return {
      pageSize: this.pageSize$.value,
      currentPage: this.currentPage$.value,
      totalPages: this.totalPages$.value,
      firstIndex: this.firstIndex$.value,
      lastIndex: this.lastIndex$.value,
      totalCount: this.totalCount$.value,
    };
  }

  getFilter(): any {
    return this.filterModel$.value;
  }

  getSearchText(): string {
    return this.searchText$.value;
  }

  getSort(): { sortField: string; sortDirection: 'asc' | 'desc' } | null {
    const sort = this.sortModel$.value;
    return sort
      ? { sortField: sort.colId, sortDirection: sort.sort as 'asc' | 'desc' }
      : null;
  }

  setPagination(pageSize: number, currentPage: number): void {
    this.pageSize$.next(pageSize);
    this.currentPage$.next(currentPage);
  }

  setFilter(filterModel: any): void {
    this.filterModel$.next(filterModel);
  }

  setSort(colId: string, sort: string): void {
    this.sortModel$.next({ colId, sort });
  }

  setSearchText(searchText: string): void {
    this.searchText$.next(searchText);
  }

  fetchData(): Observable<any[]> {
    this.loadingState$.next(true);
    return combineLatest([
      this.pageSize$.asObservable(),
      this.currentPage$.asObservable().pipe(distinctUntilChanged()),
      this.filterModel$.asObservable().pipe(distinctUntilChanged()),
      this.sortModel$.asObservable().pipe(distinctUntilChanged()),
      this.searchText$.asObservable().pipe(
        debounce(() => timer(1000)),
        distinctUntilChanged((prev, next) => prev !== next)
      ),
      this.apiUrl$.asObservable().pipe(distinctUntilChanged()),
      this.apiPayload$.asObservable().pipe(distinctUntilChanged()),
    ]).pipe(
      switchMap(
        ([
          pageSize,
          currentPage,
          filterModel,
          sortModel,
          searchText,
          apiUrl,
          apiPayload,
        ]: any[]) => {
          let request = {
            ...apiPayload,
            tableConfig: {
              pageSize: pageSize,
              page: currentPage,
              filter: filterModel,
              sort: sortModel,
              searchText: searchText,
            },
          };

          if (apiUrl == '') {
            return EMPTY;
          }

          return this.http.post<GridResponse>(apiUrl, request).pipe(
            concatMap((res: any) => {
              const tableData = res.message.tableData as GridResponse;
              this.updatePaginationData(tableData);
              return of(tableData.data);
            }),
            catchError((error) => {
              console.error('Error fetching data:', error);
              return of([]);
            })
          );
        }
      )
    );
  }

  private updatePaginationData(res: GridResponse): void {
    const totalPages = res.total_pages || 1;
    this.totalPages$.next(totalPages);
    this.currentPage$.next(res.current_page || 1);
    this.firstIndex$.next(res.first_index);
    this.lastIndex$.next(res.last_index);
    this.totalCount$.next(res.count);

    // Update visible pages for pagination UI
    const visiblePages = this.getVisiblePages(res.current_page, totalPages);
    this.pages$.next(visiblePages);
  }

  private getVisiblePages(currentPage: number, totalPages: number): number[] {
    const visiblePages = [];
    for (let i = Math.max(currentPage - 2, 1); i < currentPage; i++) {
      visiblePages.push(i);
    }
    visiblePages.push(currentPage);
    for (
      let i = currentPage + 1;
      i <= Math.min(currentPage + 2, totalPages);
      i++
    ) {
      visiblePages.push(i);
    }
    return visiblePages;
  }

  getPages(): number[] {
    return this.pages$.getValue();
  }
}
