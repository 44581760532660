import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { catchError, concatMap, EMPTY, Observable, of, throwError } from 'rxjs';

import { Store } from '@ngxs/store';
import {
  AuthState,
  Logout,
  SetAccessToken,
  SetRefreshToken,
  UpdateRefreshToken,
} from '../../ngxsstore/auth';
import { Refresh } from '@ngrx/store-devtools/src/actions';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let token = this.store.selectSnapshot(AuthState.getToken);
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
    return next.handle(request).pipe(
      concatMap((response) => {
        if (response instanceof HttpResponse) {
          if (response.headers.has('Authorization')) {
            this.store.dispatch([
              new SetAccessToken(
                response.headers.get('Authorization') as string
              ),
            ]);
          }

          if (response.headers.has('X-Refresh-Token')) {
            this.store.dispatch([
              new SetRefreshToken(
                response.headers.get('X-Refresh-Token') as string
              ),
            ]);
          }
        }
        return of(response);
      }),
      catchError((error) => {
        if (error.status == 400) {
          if (error.error.statusCode == 400) {
            return this.store.dispatch([new Logout()]);
          } else {
            return of(error);
          }
        } else if (error.status == 401) {
          if (error.error.statusCode == 401) {
            return this.store.dispatch([new Logout()]);
          } else {
            return this.store.dispatch(new UpdateRefreshToken()).pipe(
              concatMap((res) => {
                let token = this.store.selectSnapshot(AuthState.getToken);
                request = request.clone({
                  setHeaders: {
                    Authorization: `Bearer ${token}`,
                  },
                });
                return next.handle(request);
              })
            );
          }
        }
        return throwError(() => error);
      })
    );
  }
}
