import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  LUCIDE_ICONS,
  LucideAngularModule,
  LucideIconProvider,
  icons,
} from 'lucide-angular';
import { CustomInputComponent } from '../../../../Component/custom/custom-input/custom-input.component';
import { Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';

@Component({
  selector: 'app-register-cover',
  standalone: true,
  imports: [
    RouterModule,
    LucideAngularModule,
    ReactiveFormsModule,
    CustomInputComponent,
  ],
  templateUrl: './register-cover.component.html',
  styles: ``,
  providers: [
    {
      provide: LUCIDE_ICONS,
      multi: true,
      useValue: new LucideIconProvider(icons),
    },
  ],
})
export class RegisterCoverComponent {
  registerForm: FormGroup;
  isSubmit: boolean = false;

  constructor(private fb: FormBuilder, private store: Store) {
    this.registerForm = this.fb.group({
      name: this.fb.control(null, [Validators.required]),
      mobileno: this.fb.control(null, [Validators.required]),
      email: this.fb.control(null, [Validators.required]),
      password: this.fb.control(null, [Validators.required]),
    });
  }

  termsCondition() {}

  register() {
    this.store.dispatch([new Navigate(['/', 'dashboard'])]);
  }
}
